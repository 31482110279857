<div class="line-items d-flex flex-column flex-fill">
	<div *ngIf="useBarCodes && editable" style="width: 300px" class="mb-1">
		<input type="text" class="form-control" [(ngModel)]="barCode" (keydown)="onBarCodeKeyDown($event)" placeholder="Scan Bar Code..." />
	</div>
	<div *ngIf="pricingChanged" class="d-flex flex-fill align-items-center justify-content-end">
		<span class="me-2">Prices have changed.</span>
		<a *ngIf="editable" style="cursor: pointer; color: var(--bs-link-color);" (click)="refreshPricing()">Click here to update.</a>
	</div>
	<table class="table table-borderless table-sm striped">
		<colgroup *ngIf="isLathamOrder || showSendToCheckBox"><col style="width: 0; max-width: 0; padding: 0" /></colgroup>
		<colgroup></colgroup>
		<colgroup><col style="width: 15%" /></colgroup>
		<colgroup></colgroup>
		<colgroup *ngIf="!summary"><col style="width: 80px;" /></colgroup>
		<colgroup *ngIf="!summary"><col style="width: 100px;" /></colgroup>
		<colgroup *ngIf="!summary"><col style="width: 40px;" /></colgroup>
		<colgroup><col style="width: 100px;" /></colgroup>
		<colgroup *ngIf="editable"><col style="width: 50px;" /></colgroup>

		<thead class="thead-light border-bottom">
			<tr>
				<th *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></th>
				<th class="image"></th>
				<th style="text-align: left;">SKU</th>
				<th style="text-align: left;">Description&nbsp;</th>
				<th *ngIf="!summary" style="padding-right: 10px; text-align: right">Qty</th>
				<th *ngIf="!summary && showCost === false" style="padding-right: 10px; text-align: right;">Price&nbsp;<i *ngIf="isAdmin" class="far fa-sync" style="font-size: 10px; cursor: pointer; position: relative; top: -1px;" (click)="showCost = true"></i></th>
				<th *ngIf="!summary && showCost === true" style="padding-right: 10px; text-align: right;">Cost&nbsp;<i *ngIf="isAdmin" class="far fa-sync" style="font-size: 10px; cursor: pointer; position: relative; top: -1px; " (click)="showCost = false"></i></th>
				<th *ngIf="!summary" style="text-align: center;">Tax</th>
				<th style="text-align: right;">Total</th>
				<th *ngIf="editable"></th>
			</tr>
		</thead>

		<tbody>
			<!-- Line Items -->
			<tr *ngFor="let lineItem of visibleLineItems" [attr.data-uuid]="lineItem.uuid" class="line-item-row" [ngClass]="{ 'editable': lineItem.editable }">
				<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham" style="text-align: center; line-height: 22px;">
					<input *ngIf="editable && (lineItem.isParentItem || isLathamOrder)" type="checkbox" [(ngModel)]="lineItem.sendToManufacturer" />
					<i *ngIf="!editable && lineItem.sendToManufacturer === true" class="far fa-check"></i>
				</td>
				<td *ngIf="lineItem.imageThumbnailUrl" class="image" style="padding-left: 4px; padding-top: 2px;"><img [src]="lineItem.imageThumbnailUrl" (click)="enlargeImage(lineItem.imageUrl, lineItem.itemId)" /></td>
				<td *ngIf="!lineItem.imageThumbnailUrl" class="image"></td>
				<td>
					<div *ngIf="!lineItem.editable">&nbsp;{{lineItem.sku}}</div>
					<div *ngIf="lineItem.editable">
						<input #autoCompleteSkuInputRef type="text" class="form-control condensed" [(ngModel)]="lineItem.sku" autocomplete="off" placeholder="Sku" (keydown)="onLineItemKeyDown(lineItem)" />
						<slick-auto-complete [inputRef]="autoCompleteSkuInputRef"
											 [items]="skuResults"
											 showNoItemsFoundDisplay="useCustomSKU"
											 (onRefresh)="searchBySku($event)"
											 (onSelect)="replaceWithSelectedSkuItem($event, lineItem)"
											 (onFreeformTextSelect)="replaceWithSelectedSkuFreeform(lineItem)"
											 listWidth="455px">
							<ng-template let-result [slickAutoCompleteListItemTemplate]>
								<div class="d-flex flex-fill" style="white-space: pre-wrap; overflow-wrap: break-word;">
									<div *ngIf="result.imageThumbnailUrl">
										<img class="float-start" src="{{ result.imageThumbnailUrl }}" width="40" height="40" />
									</div>
									<div class="p-0 ms-1" style="line-height: 18px;">
										<b>{{ result.sku }}</b> - {{ result.description }}
									</div>
								</div>
							</ng-template>
						</slick-auto-complete>
					</div>
				</td>
				<td>
					<div *ngIf="!lineItem.editable">{{lineItem.description}}</div>
					<div *ngIf="lineItem.editable">
						<input #autoCompleteDescInputRef type="text" class="form-control condensed description" [(ngModel)]="lineItem.description" autocomplete="off" placeholder="Description" (keydown)="onLineItemKeyDown(lineItem)" />
						<slick-auto-complete [inputRef]="autoCompleteDescInputRef"
											 [items]="descResults"
											 showNoItemsFoundDisplay="false"
											 (onRefresh)="searchByDesc($event)"
											 (onSelect)="replaceWithSelectedDescItem($event, lineItem)"
											 (onFreeformTextSelect)="replaceWithSelectedDescFreeform(lineItem)"
											 listWidth="455px">
							<ng-template let-result [slickAutoCompleteListItemTemplate]>
								<div>
									<div *ngIf="result.imageThumbnailUrl" class="inline-40 p-0">
										<img class="float-start" src="{{ result.imageThumbnailUrl }}" width="40" height="40" />
									</div>
									<div class="inline-40 p-0">
										<strong class="d-block" style="line-height: 20px;">{{ result.sku }}</strong>
										<p class="p-0 m-0 desc">{{ result.description }}</p>
									</div>
								</div>
							</ng-template>
						</slick-auto-complete>
					</div>
				</td>
				<td *ngIf="!summary">
					<div *ngIf="!lineItem.editable" style="text-align: right">{{lineItem.quantity}}&nbsp;</div>
					<div *ngIf="lineItem.editable">
						<input type="number" step="any" class="form-control condensed quantity" style="text-align: right;" [(ngModel)]="lineItem.quantity" placeholder="Qty" (change)="updatePricing()" />
					</div>
				</td>
				<td *ngIf="!summary">
					<div *ngIf="showCost === false">
						<div *ngIf="!lineItem.editable" style="text-align: right">{{lineItem.price | currency}}&nbsp;</div>
						<div *ngIf="lineItem.editable">
							<input type="text" class="form-control condensed" style="text-align: right;" [slick-currency-mask] [(ngModel)]="lineItem.price" placeholder="Price" (change)="updatePricing(lineItem)" />
						</div>
					</div>
					<div *ngIf="showCost === true">
						<div *ngIf="!lineItem.editable" style="text-align: right">{{lineItem.cost | currency}}&nbsp;</div>
						<div *ngIf="lineItem.editable"><div style="text-align: right; line-height: 26px;">{{lineItem.cost | currency}}&nbsp;</div></div>
					</div>
				</td>
				<td *ngIf="!summary" style="text-align: center">
					<div *ngIf="!lineItem.editable && !lineItem.isBlankLineItem"><i *ngIf="lineItem.taxable" class="far fa-check"></i></div>
					<div *ngIf="lineItem.editable && !lineItem.isBlankLineItem">
						<input type="checkbox" [ngModel]="lineItem.taxable" style="margin-right: 0; position: relative; top: 2px;" (change)="toggleTaxable(lineItem)" />
					</div>
				</td>
				<td>
					<div *ngIf="showCost === false">
						<div *ngIf="!lineItem.editable" style="text-align: right; ">{{lineItem.price * lineItem.quantity | currency}}</div>
						<div *ngIf="lineItem.editable" style="text-align: right; line-height: 26px">{{lineItem.price * lineItem.quantity | currency}}</div>
					</div>
					<div *ngIf="showCost === true">
						<div *ngIf="!lineItem.editable" style="text-align: right; ">{{lineItem.cost * lineItem.quantity | currency}}</div>
						<div *ngIf="lineItem.editable" style="text-align: right; line-height: 26px">{{lineItem.cost * lineItem.quantity | currency}}</div>
					</div>
				</td>
				<td *ngIf="(editable && lineItem.isBlankLineItem)">&nbsp;</td>
				<!-- If this line is editable, then show some icons -->
				<td *ngIf="editable && !lineItem.isBlankLineItem" style="text-align: right;">
					<!-- If this isn't a system line item, just show delete -->
					<div *ngIf="!lineItem.isSystemLineItem" class="me-1">
						<i class="bi bi-trash" style="cursor: pointer" tabindex="-1" (click)="deleteLineItem(lineItem)"></i>
					</div>

					<div *ngIf="lineItem.isSystemLineItem && isAdmin" class="me-1">
						<!-- If this is a system line item, then only admins can edit -->
						<div *ngIf="lineItem.editable">
							<i class="far fa-check me-2" style="cursor: pointer" (click)="updateLineItem(lineItem)"></i>
							<i class="bi bi-x-circle" style="cursor: pointer" (click)="undoEdit(lineItem)"></i>
						</div>

						<div style="min-width:40px" *ngIf="!lineItem.editable">
							<i class="far fa-pencil me-2" style="cursor: pointer" tabindex="-1" (click)="lineItem.editable = true"></i>
							<i class="bi bi-trash" style="cursor: pointer" tabindex="-1" (click)="deleteLineItem(lineItem)"></i>
						</div>
					</div>
				</td>
			</tr>

			<tr *ngIf="splitLineItems && splitLineItems.length > 0">
				<td>&nbsp;</td>
				<td class="image"></td>
				<td [attr.colspan]="colspan + 1">
					Split Items (reference only - not included in totals)
				</td>
				<td *ngIf="editable">&nbsp;</td>
			</tr>
			<tr *ngFor="let splitLineItem of splitLineItems">
				<td style="text-align: right; padding-right: 0;">{{splitLineItem.invoiceGroupSeq}}</td>
				<td class="image"></td>
				<td>&nbsp;{{splitLineItem.sku}}</td>
				<td>{{splitLineItem.description}}</td>
				<td *ngIf="!summary">{{splitLineItem.quantity}}</td>
				<td *ngIf="!summary">
					<div *ngIf="showCost === false">
						<div style="text-align: right">{{splitLineItem.price | currency}}&nbsp;</div>
					</div>
					<div *ngIf="showCost === true">
						<div style="text-align: right">{{splitLineItem.cost | currency}}&nbsp;</div>
					</div>
				</td>
				<td *ngIf="!summary" style="text-align: center"><i *ngIf="splitLineItem.taxable" class="far fa-check"></i></td>
				<td>
					<div *ngIf="showCost === false">
						<div style="text-align: right; ">{{splitLineItem.price * splitLineItem.quantity | currency}}</div>
					</div>
					<div *ngIf="showCost === true">
						<div style="text-align: right; ">{{splitLineItem.cost * splitLineItem.quantity | currency}}</div>
					</div>
				</td>
				<td *ngIf="editable">&nbsp;</td>
			</tr>

			<!-- Discounts -->
			<tr *ngFor="let discount of discounts">
				<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></td>
				<td class="image"></td>
				<td>{{discount.sku}}</td>
				<td>{{discount.description}}</td>
				<td *ngIf="!summary" style="text-align: right;">1</td>
				<td *ngIf="!summary" style="text-align: right;">
					<span *ngIf='showCost'>{{discount.cost | currency}}</span>
					<span *ngIf='!showCost'>{{discount.price | currency}}</span>
				</td>
				<td *ngIf="!summary">&nbsp;</td>
				<td style="text-align: right;">
					<span *ngIf='showCost'>{{discount.cost | currency}}</span>
					<span *ngIf='!showCost'>{{discount.price | currency}}</span>
				</td>
				<td *ngIf="editable" style="text-align: right">
					<i class="bi bi-trash me-1" style="cursor: pointer" tabindex="-1" (click)="deleteDiscount(discount)"></i>
				</td>
			</tr>

			<tr>
				<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></td>
				<td class="image"></td>
				<td colspan="4" style="font-weight: bold; text-align: right;">Item Subtotal: </td>
				<td colspan="2" style="font-weight: bold; text-align: right;">
					<div *ngIf='showCost'>{{itemsSubtotals?.cost | currency}}</div>
					<div *ngIf='!showCost'>{{itemsSubtotals?.price | currency}}</div>
				</td>
				<td *ngIf="editable">&nbsp;</td>
			</tr>
		
			<!-- Labor 1 -->
			<ng-container class="" *ngIf="labor" [ngTemplateOutlet]="renderLabor" [ngTemplateOutletContext]="{ labor: labor, laborText: 'Labor 1' }"></ng-container>

			<!-- Labor 2 -->
			<ng-container *ngIf="labor2 && showLabor2" [ngTemplateOutlet]="renderLabor" [ngTemplateOutletContext]="{ labor: labor2, laborText: 'Labor 2' }"></ng-container>

			<!-- Labor 3 -->
			<ng-container *ngIf="labor3 && showLabor3" [ngTemplateOutlet]="renderLabor" [ngTemplateOutletContext]="{ labor: labor3, laborText: 'Labor 3' }"></ng-container>

			<!-- Labor 4 -->
			<ng-container *ngIf="labor4 && showLabor4" [ngTemplateOutlet]="renderLabor" [ngTemplateOutletContext]="{ labor: labor4, laborText: 'Labor 4' }"></ng-container>

			<!-- Shipping -->
			<tr *ngIf="shipping && editable">
				<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></td>
				<td class="image"></td>
				<td colspan="2" style="line-height: 26px;">&nbsp;Shipping</td>
				<td>
					<input type="number" step="any" class="form-control condensed" style="text-align: right;" [(ngModel)]="shipping.quantity" placeholder="Qty" (blur)="updatePricing()" />
				</td>
				<td>
					<input type="text" class="form-control condensed" style="text-align: right;" [slick-currency-mask] [(ngModel)]="shipping.price" placeholder="Price" (blur)="updatePricing()" />
				</td>
				<td>&nbsp;</td>
				<td>
					<div style="text-align: right; line-height: 26px;">{{shipping.price * shipping.quantity | currency}}</div>
				</td>
				<td>&nbsp;</td>
			</tr>

			<tr *ngIf="shipping && !editable && shipping.price !== 0">
				<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></td>
				<td class="image"></td>
				<td colspan="2" style="line-height: 26px;">&nbsp;Shipping</td>
				<td *ngIf="!summary">
					<div style="text-align: right; line-height: 26px;">{{shipping.quantity}}&nbsp;</div>
				</td>
				<td *ngIf="!summary">
					<div style="text-align: right; line-height: 26px;">{{shipping.price | currency}}&nbsp;</div>
				</td>
				<td *ngIf="!summary">&nbsp;</td>
				<td>
					<div style="text-align: right; line-height: 26px;">{{shipping.price * shipping.quantity | currency}}</div>
				</td>
			</tr>

			<!-- Fuel Surcharge -->
			<tr *ngIf="(useFuelSurcharge || (fuelSurcharge?.price ?? 0) !== 0) && editable">
				<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></td>
				<td class="image"></td>
				<td colspan="3" style="line-height: 26px;">&nbsp;{{fuelSurcharge.description}}</td>
				<td>
					<input type="text" class="form-control condensed" style="text-align: right;" [slick-currency-mask] [(ngModel)]="fuelSurcharge.price" placeholder="Fuel Surcharge" (blur)="updatePricing(fuelSurcharge)" />
				</td>
				<td>&nbsp;</td>
				<td>
					<div style="text-align: right; line-height: 26px;">{{fuelSurcharge.price | currency}}</div>
				</td>
				<td>&nbsp;</td>
			</tr>

			<tr *ngIf="fuelSurcharge && !editable">
				<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></td>
				<td class="image"></td>
				<td colspan="3" style="line-height: 26px;">&nbsp;{{fuelSurcharge.description}}</td>
				<td *ngIf="!summary">
					<div style="text-align: right; line-height: 26px;">{{fuelSurcharge.price | currency}}&nbsp;</div>
				</td>
				<td *ngIf="!summary">&nbsp;</td>
				<td>
					<div style="text-align: right; line-height: 26px;">{{fuelSurcharge.price | currency}}</div>
				</td>
			</tr>

			</tbody>
	</table>

	<div class="no-border mb-3">
        <table>

            <colgroup>
                <col style="width: 100%" />
                <col style="min-width: 170px" />
                <col style="min-width: 40px" />
                <col style="min-width: 100px" />
                <col style="min-width: 40px" />
            </colgroup>

            <tbody>

                <!-- Subtotal -->
                <tr>
                    <td></td>
                    <td style="font-weight: bold; text-align: right;">Subtotal:</td>
                    <td></td>
                    <td style="font-weight: bold; text-align: right;">
                        <div *ngIf='showCost'>{{subtotals?.cost | currency}}</div>
                        <div *ngIf='!showCost'>{{subtotals?.price | currency}}</div>
                    </td>
                    <td *ngIf="editable">&nbsp;</td>
                    <td></td>
                </tr>

                <tr *ngIf="!showCost && tax && (tax.cost > 0 || tax.price > 0 || tax.description === 'Tax Exempt')">
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{tax?.description}}:</td>
                    <td></td>
                    <td style="text-align: right;">
                        <!--<div *ngIf='showCost'>{{tax?.cost | currency}}</div>-->
                        <!--<div *ngIf='!showCost'>{{tax?.price | currency}}</div>-->
                        <div>{{tax?.price | currency}}</div>
                    </td>
                    <td *ngIf="editable">&nbsp;</td>
                    <td></td>
                </tr>

                <tr *ngIf="flagSaveForTaxUpdate && isMizu && !this.invoiceModel?.customer?.taxExempt">
                    <td></td>
                    <td colspan="3" style="text-align:right"><span style="border-radius:5px" class="bg-danger text-white ps-2 pe-2">Please save to update taxes.</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>

                <tr *ngIf="taxRate1 && (taxRate1.cost > 0 || taxRate1.price > 0 || taxRate1.description === 'Tax Exempt') ">
                    <td></td>
                    <td style="text-align: right;">{{taxRate1?.description}}:</td>
                    <td></td>
                    <td style="text-align: right;">
                        <div *ngIf='showCost'>{{taxRate1?.cost | currency}}</div>
                        <div *ngIf='!showCost'>{{taxRate1?.price | currency}}</div>
                    </td>
                    <td *ngIf="editable">&nbsp;</td>
                    <td></td>
                </tr>

                <tr *ngIf="taxRate2 && (taxRate2.cost > 0 || taxRate2.price > 0)">
                    <td></td>
                    <td style="text-align: right;">{{taxRate2?.description}}:</td>
                    <td></td>
                    <td style="text-align: right;">
                        <div *ngIf='showCost'>{{taxRate2?.cost | currency}}</div>
                        <div *ngIf='!showCost'>{{taxRate2?.price | currency}}</div>
                    </td>
                    <td *ngIf="editable">&nbsp;</td>
                    <td></td>
                </tr>

                <tr *ngIf="taxRate3 && (taxRate3.cost > 0 || taxRate3.price > 0)">
                    <td></td>
                    <td style="text-align: right;">{{taxRate3?.description}}:</td>
                    <td></td>
                    <td style="text-align: right;">
                        <div *ngIf='showCost'>{{taxRate3?.cost | currency}}</div>
                        <div *ngIf='!showCost'>{{taxRate3?.price | currency}}</div>
                    </td>
                    <td *ngIf="editable">&nbsp;</td>
                    <td></td>
                </tr>

                 <!--ColoradoShippingSurcharge--> 
                <tr *ngIf="coloradoShippingSurcharge && (coloradoShippingSurcharge.cost > 0 || coloradoShippingSurcharge.price > 0)">
                    <td></td>
                    <td style="text-align: right;">{{coloradoShippingSurcharge?.description}}:</td>
                    <td></td>
                    <td style="text-align: right;">
                        <div *ngIf='showCost'>{{coloradoShippingSurcharge?.cost | currency}}</div>
                        <div *ngIf='!showCost'>{{coloradoShippingSurcharge?.price | currency}}</div>
                    </td>
                    <td *ngIf="editable">&nbsp;</td>
                    <td></td>
                </tr>

                <!-- Tip -->
                <tr *ngIf="allowTips && tip && tip.price > 0">
                    <td></td>
                    <td style="text-align: right;">{{tip.description}}:</td>
                    <td></td>
                    <td *ngIf="!summary" style="text-align: right">
                        <div>{{tip.price | currency}}&nbsp;</div>
                    </td>
                    <td style="text-align: right">
                        <div>{{tip.price | currency}}</div>
                    </td>
                    <td></td>
                </tr>

                <!-- Add this empty row for spacing -->
                <tr style="height: 10px;"></tr>

                <!-- Final total -->
                <tr>
                    <td></td>
                    <td style="font-weight: bold; text-align: right;">Total:</td>
                    <td></td>
                    <td style="font-weight: bold; text-align: right;">
                        <div *ngIf='showCost'>{{totals?.cost | currency}}</div>
                        <div *ngIf='!showCost'>{{totals?.price | currency}}</div>
                    </td>
                    <td *ngIf="editable">&nbsp;</td>
                    <td></td>
                </tr>

                <tr *ngIf="appliedPaymentsTotal > 0">
                    <td></td>
                    <td style="text-align: right;">Applied Payments:</td>
                    <td></td>
                    <td style="text-align: right;">{{appliedPaymentsTotal | currency}}</td>
                    <td *ngIf="editable">&nbsp;</td>
                    <td></td>
                </tr>

                <tr *ngIf="showBalanceDue">
                    <td></td>
                    <td style="font-weight: bold; text-align: right; border-top: 2px solid var(--bs-border-color);">Balance Due:</td>
                    <td style="border-top: 2px solid var(--bs-border-color);"></td>
                    <td style="font-weight: bold; text-align: right; border-top: 2px solid var(--bs-border-color);">
                        <div>{{amountDue | currency}}</div>
                    </td>
                    <td></td>
                </tr>

            </tbody>
        </table>
	</div>

	<div class="paid-img" *ngIf="isPaid"></div>
	<div class="no-charge-img" *ngIf="isNoCharge"></div>
</div>

<ng-template #renderLabor let-labor="labor" let-laborText="laborText">
	<tr *ngIf="editable && laborItems">
		<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></td>
		<td class="image"></td>
		<td *ngIf="laborItems" colspan="2">
			<div class="d-flex">
				<div class="me-2 ms-1" style="width: 180px">
					<slick-drop-down [(ngModel)]="labor.sku"
									 [items]="laborItems"
									 idFieldName="sku"
									 textFieldName="sku"
									 placeholder="Labor"
									 compact="true"
									 cssClass="condensed-dropdown"
									 (onSelect)="onLaborSelected($event, labor)"></slick-drop-down>
				</div>
				<div class="flex-fill">
					<input *ngIf="labor.laborItemId" type="text" class="form-control condensed" style="width: 100%;	height: 28px;" [(ngModel)]="labor.description" />
				</div>
			</div>
		</td>
		<td style="text-align: right">
			<input *ngIf="labor.laborItemId" type="number" step="any" class="form-control condensed" style="text-align: right; height: 28px;" [(ngModel)]="labor.quantity" placeholder="Qty" (blur)="updatePricing()" />
		</td>
		<td style="text-align: right">
			<input *ngIf="labor.laborItemId" type="text" class="form-control condensed" style="text-align: right; height: 28px;" [slick-currency-mask] [(ngModel)]="labor.price" placeholder="Price" (blur)="updatePricing()" />
		</td>
		<td>&nbsp;</td>
		<td style="text-align: right; line-height: 28px;">{{labor.quantity * labor.price | currency}}</td>
		<td *ngIf="editable">&nbsp;</td>
	</tr>
	<tr *ngIf="editable && !laborItems">
		<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></td>
		<td class="image"></td>
		<td>
			<input type="text" class="form-control condensed" [(ngModel)]="labor.sku" [placeholder]="laborText" />
		</td>
		<td>
			<input type="text" class="form-control condensed" [(ngModel)]="labor.description" />
		</td>
		<td style="text-align: right">
			<input type="number" step="any" class="form-control condensed" style="text-align: right;" [(ngModel)]="labor.quantity" placeholder="Qty" (blur)="updatePricing()" />
		</td>
		<td style="text-align: right">
			<input type="text" class="form-control condensed" style="text-align: right; " [slick-currency-mask] [(ngModel)]="labor.price" placeholder="Price" (blur)="updatePricing()" />
		</td>
		<td>&nbsp;</td>
		<td style="text-align: right; line-height: 26px;">{{labor.quantity * labor.price | currency}}</td>
		<td>&nbsp;</td>
	</tr>
	<tr *ngIf="!editable && (labor.quantity * labor.price) !== 0">
		<td *ngIf="isLathamOrder || showSendToCheckBox" class="send-to-latham"></td>
		<td class="image"></td>
		<td>&nbsp;{{labor.sku}}</td>
		<td>{{labor.description}}</td>
		<td *ngIf="!summary" style="text-align: right">{{labor.quantity}}&nbsp;</td>
		<td *ngIf="!summary" style="text-align: right">{{labor.price | currency}}&nbsp;</td>
		<td *ngIf="!summary">&nbsp;</td>
		<td style="text-align: right">{{labor.quantity * labor.price | currency}}</td>
	</tr>
</ng-template>

<slick-dialog #enlargedImageDialogRef header="Image" [width]="600">
	<!--<slick-tabs [(tabIndex)]="tabIndex" (tabChange)="onTabChanged($event)">
		<slick-tab header="Image">-->
			
			<div style="min-height: 500px; min-width: 500px; line-height: 500px; vertical-align: central; text-align: center">
				<img [src]="enlargedImageUrl" style="object-fit: contain; max-width: 500px; max-height: 500px" />
			</div>
		<!--</slick-tab>-->

		<!--<slick-tab header="Item Assemblies">-->
		<h3 class="border-bottom">{{selectedItem?.sku}} Item Assemblies</h3>
			<div *ngIf="skus.length > 1" (click)="goBack()" style="cursor:pointer; max-width:150px"><i class="text-primary bi bi-arrow-left" ></i> Previous Item</div>
			<div *ngIf="selectedItem">
				<table *ngIf="selectedItem.itemAssemblies && selectedItem.itemAssemblies?.length > 0" class="table table-striped table-sm mt-3 text-primary">
					<colgroup>
						<col style="width: 40px" />
						<col style="width: 60px" />
						<col style="width: 40px" />
						<col />
						<col *ngIf="isMizu" style="width: 80px" />
						<col *ngIf="isMizu" style="width: 80px" />
					</colgroup>
					<thead>
						<tr>
							<td></td>
							<td>Sku</td>
							<td>Qty</td>
							<td>Description</td>
							<td *ngIf="isMizu">Cost</td>
							<td *ngIf="isMizu">Total</td>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let assemblyItem of selectedItem.itemAssemblies">
							<td><img [src]="assemblyItem.imageThumbnailUrl" style="max-width: 60px; max-height: 40px;" /></td>
							<td *ngIf="!isMizu">{{assemblyItem.sku}}</td>
							<td *ngIf="isMizu" class="btn-link text-primary" style="cursor:pointer" (click)="onItemAssemblySkuClick(assemblyItem.sku)">{{assemblyItem.sku}}</td>
							<td>{{assemblyItem.quantity}}</td>
							<td>{{assemblyItem.description}}</td>
							<td *ngIf="isMizu">{{assemblyItem.cost | currency}}</td>
							<td *ngIf="isMizu">{{assemblyItem.totalCost | currency}}</td>
						</tr>
					</tbody>
				</table>
				<div style="width:500px; height:500px" *ngIf="!selectedItem.itemAssemblies || selectedItem.itemAssemblies.length === 0">
					No Item Assemblies
				</div>
				<button type="button" class="mt-2 btn btn-link" *ngIf="selectedItem.isGlobalItem" (click)="updateItemAssemblies()">Refresh Latham Item Assemblies</button>

			</div>

		<!--</slick-tab>

	</slick-tabs>-->
	<slick-dialog-footer>
		<button class="btn btn-outline-primary" style="width: 120px;" (click)="closeImageDialog()">Ok</button>
	</slick-dialog-footer>
</slick-dialog>